class FarloUiTicketCalendar {
	constructor(element, _config) {
		this.container = element;
		this.options = _config;
		if (this.container.dataset && this.container.dataset.startMonth) {
			this.currentDate = new Date(this.container.dataset.startMonth);
		} else {
			this.currentDate = new Date();
		}
		this.render();
	}

	renderHeader() {
		const header = document.createElement('div');
		header.classList.add('site-farlo-ui-ticket-calendar__header');

		const prevButton = document.createElement('button');
		prevButton.classList.add('site-farlo-ui-ticket-calendar__button', 'site-farlo-ui-ticket-calendar__button--prev', 'button');
		prevButton.textContent = '<';
		prevButton.addEventListener('click', () => this.prevMonth());
		header.appendChild(prevButton);

		const title = document.createElement('h2');
		title.classList.add('site-farlo-ui-ticket-calendar__header-month-year');
		title.textContent = this.currentDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long' });
		header.appendChild(title);

		const nextButton = document.createElement('button');
		nextButton.classList.add('site-farlo-ui-ticket-calendar__button', 'site-farlo-ui-ticket-calendar__button--next', 'button');
		nextButton.textContent = '>';
		nextButton.addEventListener('click', () => this.nextMonth());
		header.appendChild(nextButton);

		this.container.appendChild(header);
	}

	renderDaysOfTheWeeks() {
		const div = document.createElement('div');
		div.classList.add('site-farlo-ui-ticket-calendar__weeks');
		const daysLetters = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];

		for (let i = 0; i < 7; i += 1) {
			const days = document.createElement('div');

			days.classList.add('site-farlo-ui-ticket-calendar__week-day');
			days.textContent = daysLetters[ i ];
			div.appendChild(days);
		}

		this.container.appendChild(div);
	}

	async fetchEventsForMonth(y, m) {
		const data = new URLSearchParams({
			action: 'getEventsForMonth',
			nonce: farloAjaxVendorHandler.nonce,
			month: m,
			year: y
		});

		this.container.classList.add('site-farlo-ui-ticket-calendar--loading');

		try {
			const response = await fetch(farloAjaxVendorHandler.ajaxurl, {
				method: 'POST',
				body: data,
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/x-www-form-urlencoded',
					'X-WP-Nonce': farloAjaxVendorHandler.nonce
				}
			});

			if (response.ok) {
				const responseData = await response.json(); // Assuming the server returns JSON
				this.container.classList.remove('site-farlo-ui-ticket-calendar--loading');

				// Clear the container before adding new content
				this.container.innerHTML = '';
				this.renderHeader();
				this.renderDaysOfTheWeeks();

				if (responseData.data.calendarCells !== '') {
					this.container.insertAdjacentHTML('beforeend', responseData.data.calendarCells);
				}
			} else {
				this.container.insertAdjacentHTML('beforeend', '<p class="site-farlo-ui-ticket-calendar__no-events">There are currently no available events. Please check back at another time.</p>');
				console.error('Response status not OK:', response.status);
			}
		} catch (error) {
			console.error('There was an error!', error);
		}
	}

	async render() {
		this.container.innerHTML = '';
		this.renderHeader();
		this.renderDaysOfTheWeeks();
		this.fetchEventsForMonth(this.currentDate.getFullYear(), this.currentDate.getMonth() + 1);
	}

	prevMonth() {
		this.currentDate.setMonth(this.currentDate.getMonth() - 1);
		this.render();
	}

	nextMonth() {
		this.currentDate.setMonth(this.currentDate.getMonth() + 1);
		this.render();
	}

	handleEventClick(event) {
		if (this.options.eventClick) {
			this.options.eventClick(event);
		}
	}
}

HTMLDivElement.prototype.farloUiTicketCalendar = function(settings) {
	return new FarloUiTicketCalendar(this, settings);
};
