/**
 * Farlo UI | Lightbox
 *
 * Author: Farlo
 */

class FarloUiLightbox {
	constructor(element, _config) {
		this.element = element;
		this.options = {
			accessible: true,
			controls: true,
			draggable: true,
			pagination: true,
			touch: true,
			..._config
		};
		this.items = [];
		this.currentIndex = 0;
		this.lightboxElement = null;
		this.contentElement = null;
		this.closeButton = null;

		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.showItem = this.showItem.bind(this);

		this.init();
	}

	init() {
		const toggleButton = this.element.nextElementSibling;
		if (toggleButton) {
			toggleButton.addEventListener('click', this.open);
		}

		// Find all lightbox items within the block
		const lightboxItems = this.element.querySelectorAll('.site-farlo-ui-lightbox__inner > div[data-type]');
		lightboxItems.forEach((item, index) => {
			item.addEventListener('click', (event) => {
				event.preventDefault();
				this.currentIndex = index;
				this.open();
			});
			this.items.push(item);
		});

		window.addEventListener('resize', this.handleWindowResize);
	}

	open() {
		// Show the lightbox overlay
		this.element.style.display = 'block';
		this.element.classList.add('site-farlo-ui-lightbox--active');

		// Create the content container
		this.contentElement = this.element.querySelector('.site-farlo-ui-lightbox__inner');

		// Create and append the close button
		if (this.options.controls) {
			this.closeButton = document.createElement('button');
			this.closeButton.classList.add('site-farlo-ui-lightbox__close-button');
			this.closeButton.addEventListener('click', this.close);
			this.element.appendChild(this.closeButton);
		}

		// Show the initial item
		this.showItem();

		// Add event listeners for keyboard navigation
		document.addEventListener('keydown', this.handleKeyDown);
	}

	close() {
		// Hide the lightbox and remove its contents
		this.element.style.display = 'none';
		this.element.classList.remove('site-farlo-ui-lightbox--active');
		this.contentElement.innerHTML = '';

		// Remove event listeners
		document.removeEventListener('keydown', this.handleKeyDown);
	}

	showItem() {
		const currentItem = this.items[ this.currentIndex ];
		const itemType = currentItem.dataset.type;

		// Clear the content element
		this.contentElement.innerHTML = '';

		// Load and display the appropriate item
		if (itemType === 'image') {
			// Handle image content
			const imageElement = currentItem.querySelector('img');
			const clonedImageElement = imageElement.cloneNode(true);
			this.contentElement.appendChild(clonedImageElement);
		} else if (itemType === 'text') {
			// Handle text content
			const textElement = currentItem.querySelector('.farlo-ui-lightbox-content-text');
			const clonedTextElement = textElement.cloneNode(true);
			this.contentElement.appendChild(clonedTextElement);
		} else if (itemType === 'video') {
			// Handle video content
			const videoElement = currentItem.querySelector('.farlo-ui-lightbox-content-video');
			const clonedVideoElement = videoElement.cloneNode(true);
			this.contentElement.appendChild(clonedVideoElement);
		}
	}

	handleWindowResize() {
		// Handle window resize event
	}

	handleKeyDown(event) {
		// Handle keyboard navigation
		if (event.key === 'Escape') {
			this.close();
		} else if (event.key === 'ArrowLeft') {
			this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
			this.showItem();
		} else if (event.key === 'ArrowRight') {
			this.currentIndex = (this.currentIndex + 1) % this.items.length;
			this.showItem();
		}
	}
}

HTMLDivElement.prototype.farloUiLightbox = function(settings) {
	return new FarloUiLightbox(this, settings);
};
